@import '../../styles/mixins.scss';


.table {
  width: 100%;

  .tableTop {
    @include flex($gap: 0);
    @include container($borderRadius: $radiusBig, $backgroundColor: $neutralLight10, $boxShadow: none, $padding: 0.5rem, $border: 0);
    width: 100%;

    .spacer {
      width: 2.75rem; // DO NOT CHANGE AT RANDOM! This has to match the width the checkbox ocupies
    }

    .header {
      @include font($fontFamily: $redHatDisplay, $fontWeight: 600, $color: $neutralDark30);
      text-align: center;
      border-left: 2px solid black;
    }
  }

  .tableContent {
    padding: 0.25rem 0;

    .row {
      @include size(100%, 2.75rem);
      @include flex($gap: 0rem);
      cursor: pointer;
      padding: 0 0.5rem;
      border-radius: $radiusBig;

      .checkbox {
        @include size(1.75rem, 1.75rem);
        @include container($borderColor: $neutralLight10, $border: 2px solid);
        margin-right: 1rem;

        .checkMark {
          @include size(100%, 100%);
          opacity: 0;
          object-fit: contain;
          filter: $filterWhite;
        }
      }

      .cell {
        @include size(100%, 100%);
        @include flex($justifyContent: center);
        overflow: hidden;
        text-align: center;
        border-left: 2px solid black;

        &:nth-child(odd) {
          background-color: rgba($neutralDark20, 0.15);
        }
      }

      &.checked {
        .checkbox {
          background-color: $primary;
          border-color: $primary;

          .checkMark {
            opacity: 1;
            color: $white;
          }
        }
      }

      &.hovered {
        background-color: $neutralDark20;

        &.mouseDown {
          background-color: $neutralDark30;
        }
      }

      &.disabled {
        cursor: not-allowed;

        .checkbox {
          background-color: $neutralLight20;
        }
      }
    }
  }

  .tableBottom {
    @include flex($justifyContent: space-between);
    width: 100%;
  }
}
