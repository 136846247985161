@import '../../styles/mixins.scss';

$containerPadding: 1rem;

.dropdown {
  @include size(100%, 100%);
  position: relative;
  z-index: 10000;

  .topContainer {
    @include container($borderRadius: $radiusSmall, $padding: $containerPadding, $border: 2px solid, $borderColor: $neutralLight10);
    @include flex($alignItems: flex-start, $gap: 0rem);
    @include size(100%, 100%);
    text-align: left;
    cursor: pointer;
    // z-index so that the box-shadow doesn't get cut off by the dropped list
    position: relative;
    z-index: 11;

    .info {
      @include font();
      @include flex($flexDirection: column, $alignItems: flex-start, $gap: 0.15rem);
      text-align: left;
      overflow: hidden;
      width: 100%;

      .title {
        @include font();
        color: $neutralLight10;
      }

      .text {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .rightInfo {
      @include flex($gap: 0.5rem);
      height: 100%;

      .counter {
        @include font();
        white-space: nowrap;
        align-self: flex-end;
      }

      .imgContainer {
        // 50% of the parent's height, the plus $containerPadding is to use 50% of the parent's padding
        height: calc(50% + $containerPadding);
      }
    }

    &.disabled {
      background-color: $neutralLight20;
      pointer-events: none;
    }

    &.pill {
      border-radius: 100vh;
    }
  }

  .droppedContainer {
    @include size(100%, 50%);
    position: absolute;
    top: 50%;

    .droppedTopBackground {
      @include container($borderRadius: 0, $borderColor: $neutralLight10, $border: 2px solid, $boxShadow: none);
      @include size(100%, 100%);
      border-bottom: none;
      background-color: $white;
    }

    .dropedList {
      @include container($borderRadius: 0 0 $radiusMedium $radiusMedium, $borderColor: $neutralLight10, $border: 2px solid, $boxShadow: none);
      @include flex($flexDirection: column, $gap: 0);
      border-top: none;
      width: 100%;
      position: absolute;
      z-index: 10;

      .optionContainer {
        width: 100%;
      }
    }
  }

  &.disabled {
    background-color: $neutralLight10;
    cursor: not-allowed;
  }
}
