@import '../../styles/mixins.scss';

$wizardFiltersNumber: 5;
$tableFiltersNumber: 6;

.container {
  @include flex($gap: 0.5rem);
  @include size(100%, 4.75rem);

  .filters {
    @include size(100%, auto);
    display: grid;
    gap: 0.5rem;

    >div {
      height: 4.75rem;
    }

    &.wizard {
      grid-template-columns: repeat($wizardFiltersNumber, calc(100% / $wizardFiltersNumber - 0.5rem));
    }

    &.table {
      grid-template-columns: repeat($tableFiltersNumber, calc(100% / $tableFiltersNumber - 0.5rem));
    }
  }
}
