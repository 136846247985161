@import '../../styles/mixins.scss';

.step1 {
  @include size(100%, 100%);
  @include flex($flexDirection: column, $justifyContent: space-between, $alignItems: stretch);

  .selectNinjaToAssignContainer {
    @include flex($flexDirection: column);

    >:first-child {
      @include size(100%, 3rem);
    }
  }
}

.step2 {
  @include flex($flexDirection: column, $alignItems: stretch);

  .selectedNinja {
    @include flex($justifyContent: space-between);
    @include container($padding: 0.5rem, $borderColor: $primaryLight50);

    .info {
      border-left: none;
      color: $primaryLight20;

      &:nth-child(1) {
        flex-grow: 1;
      }

      &:nth-child(2),
      &:nth-child(3) {
        flex-grow: 2;
      }
    }
  }

  .selectedMSLabels {
    @include flex($justifyContent: space-between);
    @include container($padding: 0.5rem, $backgroundColor: $neutralDark20, $borderColor: $neutralDark20);
    margin-bottom: 0.5rem;

    .info {
      color: $neutralLight20;
    }
  }

  .selectedMissionStoresList {
    @include size(100%, 18.25rem);
    overflow: scroll;

    .selectedMissionStoreDetail {
      @include flex($justifyContent: space-between);
      @include container($padding: 0.5rem, $borderColor: $neutralLight20);
      margin-bottom: 0.5rem;
    }
  }

  .info {
    flex: 1;
    text-align: center;
    border-left: 1px solid $neutralLight20;

    &:nth-child(1) {
      border-left: none;
    }
  }
}

.buttons {
  @include flex($justifyContent: space-between);
}
