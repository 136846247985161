@import '../../styles/mixins.scss';

.imgContainer {
  @include container($borderRadius: $radiusSmall, $border: 2px solid, $borderColor: $neutralLight10);
  @include flex($justifyContent: center, $alignItems: center);
  @include size(100%, 100%);
  overflow: hidden;
  position: relative;

  .label {
    @include size(calc(100% - 4px), 1.5rem);
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    top: 0;
  }

  .photoSmall {
    @include size(100%, 100%);
    object-fit: contain;
  }

  .noPhoto {
    text-align: center;
    font-style: italic;
    color: $neutral;
  }
}

.previewContainer {
  @include size(100vw, 100vh);
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;

  .photoBig {
    @include size(95%, calc(100% - 2rem));
    margin-top: 1rem;
    margin-left: 50%;
    transform: translateX(-50%);
    object-fit: contain;
  }

  .buttons {
    @include flex($justifyContent: space-between);
    position: absolute;
    width: calc(100% - 4rem);
    top: 50%;
    left: 2rem;
  }
}
