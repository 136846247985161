@import '../../styles/mixins.scss';

.textInput {
  @include size(100%, 100%);
  @include flex($flexDirection: column, $justifyContent: center, $alignItems: flex-start, $gap: 0);
  @include container($borderRadius: $radiusSmall, $padding: 1rem, $border: 2px solid, $borderColor: $neutralLight10);

  .title {
    @include font();
    color: $neutralLight10;
    margin-bottom: 0.5rem;
  }

  .flex {
    @include flex();
    @include size(100%, 100%);

    .input {
      @include font();
      width: 100%;
      border: 0;
      outline: 0;
      resize: none;

      &.disabled {
        background-color: $neutralLight20;
        pointer-events: none;
      }
    }
  }

  &.noBorders {
    border: none;
  }

  &.disabled {
    background-color: $neutralLight20;
    cursor: not-allowed;
  }

  &.pill {
    border-radius: 100vh;
  }
}
