@import '../styles/mixins.scss';

.modalWrapper {
  @include flex($justifyContent: center);
  @include size(100vw, 100vh);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;

  .content {
    @include size(50rem, 40rem);
    @include container($padding: 2rem);
    @include flex($flexDirection: column);
    cursor: default;

    .header {
      @include size(100%, 3rem);
      @include flex($justifyContent: space-between);
    }

    .body {
      @include size(100%, 100%);
    }
  }
}
