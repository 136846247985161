@import '../../styles/mixins.scss';

.primary {
  @include flex($gap: 0.75rem);
  justify-content: center;
  margin-top: 1rem;

  .dots {
    width: 2rem;
    color: $primary;
    text-align: center;

    &.loading {
      color: $primaryLight40;
    }
  }

  .bigSpacer {
    width: 5.75rem;
  }

  .buttonSpacer {
    width: 3rem;
  }
}

.table {
  @include flex($gap: 0rem);

  .dots {
    @include font($fontSize: 0.75rem, $color: $neutralDark20);
  }
}
