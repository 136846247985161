@import '../../styles/mixins.scss';

.container {
  @include size(100%, 100%);
  @include flex($flexDirection: column, $alignItems: flex-start);
  padding-top: 1rem;

  .crowdsourceLabel {
    @include flex($gap: 0.25rem);
    @include font($fontFamily: $redHatDisplay, $fontWeight: 500, $fontSize: 0.85rem, $color: $secondaryDark40);

    .crowdsourceIcon {
      @include size(0.85rem, 0.85rem);
      filter: $filterSecondaryDark40;
    }
  }
}
